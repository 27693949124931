import { Link } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout';

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <center style={{ marginTop: 20 }}>
          <h1>Not Found</h1>
          <p>You just hit a page that doesn&#39;t exist...</p>
          <Link to='/'>Go back home</Link>
        </center>
      </Layout>
    );
  }
}

export default NotFoundPage;
